import '@/common/css/index.scss';
import './index.scss';
import $ from 'jquery';

var WOW = window.WOW;
var clientHeight = document.documentElement ? document.documentElement.clientHeight : document.body.clientHeight;

/**
 * 初始化滚动动画对象
 */
var wow = new WOW({
  boxClass: 'wow',
  animateClass: 'animated',
  offset: 0,
  mobile: true,
  live: true
});

/**
 * 滚动网页设置网页头部样式
 */
function setHeaderClass() {
  var scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
  if (scrollTop >= clientHeight) {
    // console.log(clientHeight, scrollTop);
    // 滚动超过首屏隐藏banner
    document.querySelector('.banner').style.display = 'none';
    document.getElementById('header').className = 'header fixed';
  } else {
    document.getElementById('header').className = 'header';
    document.querySelector('.banner').style.display = 'block';
  }
}

window.onload = function() {
  setTimeout(function() {
    window.scrollTo(0, 0);
  }, 100);
  wow.init();
  window.addEventListener('scroll', setHeaderClass);
  setHeaderClass();
};

!(function() {
  $(function() {
    $('#J_close').on('click', function() {
      $('.zsjd-wrap').hide();
    });
  });
})();
